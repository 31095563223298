import React, { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";
import { Backdrop, Grid, Typography } from "@mui/material";
import Dashboard from "../pages/Dashboard";
import Landingpage from "../pages/Landingpage";
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { LoaderUtils } from '../services/Utils';
import LoadingImage from "./../assets/loader.gif";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Customers = lazy(() => import("../pages/Customers"));
const Credentials = lazy(() => import("../pages/Credentials"));
const Certifications = lazy(() => import("../pages/Certifications"));
const Locations = lazy(() => import("../pages/Locations"));
const Employee = lazy(() => import("../pages/Employees"));
const Projects = lazy(() => import("../pages/Projects"));
const Login = lazy(() => import("../pages/Login"));
const CustomerForm = lazy(() => import("../pages/Customers/CustomerForm"));
const CertificateForm = lazy(() => import("../pages/Certifications/CertificateForm"));
const LocationForm = lazy(() => import("../pages/Locations/LocationForm"));
const Employees = lazy(() => import("../pages/Employees"));
const EmployeeForm = lazy(() => import("../pages/Employees/EmployeeForm"));
const ProjectForm = lazy(() => import("../pages/Projects/ProjectForm"));
const Trainings = lazy(() => import("../pages/Trainings"));
const TrainingForm = lazy(() => import("../pages/Trainings/TrainingForm"));
const CredentialForm = lazy(() => import("../pages/Credentials/CredentialForm"));
const Skills = lazy(() => import("../pages/Skills"));
const PPEs = lazy(() => import("../pages/PPE"));
const Equipments = lazy(() => import("../pages/Equipments"));
const SkillForm = lazy(() => import("../pages/Skills/SkillsForm"));
const PPEForm = lazy(() => import("../pages/PPE/PPEForm"));
const EquipmentForm = lazy(() => import("../pages/Equipments/EquipmentForm"));
const Teams = lazy(() => import("../pages/Teams"));
const Roles = lazy(() => import("../pages/Roles"));
const RolesForm = lazy(() => import("../pages/Roles/RoleForm"));
const MyTeam = lazy(() => import("../pages/MyTeam"));
const ProfileForm = lazy(() => import("../pages/MyTeam/ProfileForm"));
const Subscriptions = lazy(()=> import("../pages/Subscriptions"))

const renderLoader = () => <p>Loading</p>

export default function AppRoutes() {

  const {
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    loginWithRedirect,
    logout,
    user,
    error
  } = useAuth0();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && (isAuthenticated && !localStorage.getItem("access_token"))) {
      getToken();
    }
    else if (!isLoading && !isAuthenticated && error) {
      toast(error.error, { type: "error" });
      localStorage.clear();
      logout();
    }
    else if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
    // eslint-disable-next-line
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);


  const getToken = async () => {
    LoaderUtils.showLoader();
    try {
      const token = await getAccessTokenSilently();
      LoaderUtils.hideLoader();
      localStorage.setItem("access_token", token);
      const OrganizationSubsriptionDetails = user[`${process.env.REACT_APP_AUTH_URL}OrganizationSubsriptionDetails`];
      const role = user[`${process.env.REACT_APP_AUTH_URL}role`][0];
      if (!OrganizationSubsriptionDetails?.isSubscriptionActive && role === "Tenant") {
        navigate("/subscriptions?isSubscriptionActive=false");
      }
      else {
        navigate("/customers");
      }
    } catch (e) {
      LoaderUtils.hideLoader();
      localStorage.clear();
      logout();
    }
  };

  if (!isAuthenticated) {
    return <Backdrop open={true}>
      <Grid container display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Grid container height={"100vh"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
          <img
            className="loading-indicator"
            height={"80px"}
            width={"80px"}
            alt="loading-indicator"
            src={LoadingImage}
          />
        </Grid>
      </Grid>
    </Backdrop>
  }

  const isTenant = localStorage.getItem("role") === "Tenant";

  return (
    <Suspense fallback={renderLoader()}>
      <Routes>
        <Route path="" element={<Typography>Coming Soon...</Typography>} />
        <Route path="home" element={<Navigate to="/customers" replace={true} />} />
        <Route path="customers">
          <Route index element={<Customers />} />
          <Route path="new" element={<CustomerForm />} />
          <Route path=":id" element={<CustomerForm />} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="certification">
          <Route index element={<Certifications />} />
          <Route path="new" element={<CertificateForm />} />
          <Route path=":id" element={<CertificateForm />} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="credential">
          <Route index element={<Credentials />} />
          <Route path="new" element={<CredentialForm />} />
          <Route path=":id" element={<CredentialForm />} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="skill">
          <Route index element={<Skills />} />
          <Route path="new" element={<SkillForm />} />
          <Route path=":id" element={<SkillForm />} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="ppe">
          <Route index element={<PPEs />} />
          <Route path="new" element={<PPEForm />} />
          <Route path=":id" element={<PPEForm />} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="equipment">
          <Route index element={<Equipments />} />
          <Route path="new" element={<EquipmentForm />} />
          <Route path=":id" element={<EquipmentForm />} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="employees">
          <Route index element={<Employees />} />
          <Route path="new-employee" element={<EmployeeForm />} />
          <Route path=":id" element={<EmployeeForm />} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="locations">
          <Route index element={<Locations />} />
          <Route path="new-location" element={<LocationForm />} />
          <Route path=":id" element={<LocationForm />} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="projects">
          <Route index element={<Projects />} />
          <Route path="new-project" element={<ProjectForm />} />
          <Route path=":id" element={<ProjectForm />} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="teams">
          <Route index element={<Teams />} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="training">
          <Route index element={<Trainings />} />
          <Route path="new" element={<TrainingForm />} />
          <Route path=":id" element={<TrainingForm />} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="roles">
          <Route index element={isTenant ? <Roles /> : <Typography>Page Not Found</Typography>} />
          <Route path="new" element={isTenant ? <RolesForm /> : <Typography>Page Not Found</Typography>} />
          <Route path=":id" element={isTenant ? <RolesForm /> : <Typography>Page Not Found</Typography>} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="my-team">
          <Route index element={isTenant ? <MyTeam /> : <Typography>Page Not Found</Typography>} />
          <Route path=":id" element={isTenant ? <ProfileForm /> : <Typography>Page Not Found</Typography>} />
          <Route path="*" element={<Typography>Page Not Found</Typography>} />
        </Route>
        <Route path="employee" element={<Employee />} />
        <Route path="login" element={<Login />} />
        <Route path="my-profile" element={<ProfileForm />} />
        <Route path="subscriptions" element={isTenant ? <Subscriptions /> : <Typography>Page Not Found</Typography>} />
        <Route path="*" element={<Typography>Page Not Found</Typography>} />
      </Routes>  
    </Suspense>
  );
}
