import CssBaseline from "@mui/material/CssBaseline";
import React from "react";
import MainMenu from "../pages/MainMenu";
import AppRoutes from "../routing";
import { Container, MainBox } from "../styledComponents/MainMenuLayout.styled";

const Layout = () => {
  return (
    <Container>
      <CssBaseline />
      <MainMenu />
      <MainBox component="main">
        <AppRoutes />
      </MainBox>
    </Container>
  );
};
export default Layout;
