import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

var reactPlugin = new ReactPlugin();

const appInsightsConfig = {
    instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY, // Add instrumentationKey
    enableAutoRouteTracking: true, // Add enableAutoRouteTracking
    extensions: [reactPlugin], // Add extensions
};

export const initializeAppInsights = () => {
    const appInsights = new ApplicationInsights({
        config: appInsightsConfig,
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView(); // Automatically track page views

    return appInsights;
};
