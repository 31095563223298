import { addMinutes, format, parse } from "date-fns";
import { FORM } from "../../constants/forms";

const ErrorType = {
  REQUIRED: "required",
  PATTERN: "pattern",
  CUSTOM: "custom",
};

const getFormValues = (payload, ignoreBlankValue = false) => {
  let values = {};
  Object.keys(payload).forEach((fieldKey) => {
    const currentField = payload[fieldKey];
    values = {
      ...values,
      [fieldKey]: currentField.value ?? "",
    };
  });
  if (ignoreBlankValue) {
    Object.keys(values).forEach((fieldKey) => {
      const currentValue = values[fieldKey];
      (currentValue === "" || currentValue === undefined || currentValue === null || currentValue == 0) && delete values[fieldKey];
    });
  }
  return values;
};

const getFormValuesWithPayload = (value, payload) => {
  let formPayload = payload;
  Object.keys(formPayload).forEach((fieldKey) => {
    formPayload = {
      ...formPayload,
      [fieldKey]: {
        ...formPayload[fieldKey],
        value: (value && value[fieldKey]) ?? null,
      },
    };
  });
  return formPayload;
};

const validateFields = (fields, otherCondition = true) => {
  let newFields = { ...fields };
  let valid = true;
  Object.keys(fields).forEach((fieldKey) => {
    const currentField = fields[fieldKey];
    const currentValue = currentField.value;
    const error = otherCondition
      ? validateCommonError(currentField, currentValue)
      : "";
    newFields = {
      ...newFields,
      [fieldKey]: {
        ...newFields[fieldKey],
        value: currentValue,
        error,
      },
    };
    if (error) valid = "";
  });
  return { valid, newFields };
};

const validateCommonError = (field, value) => {
  let error = "";
  if (field?.required && !value) {
    error = ErrorType.REQUIRED;
  } else if (field?.required || value) {
    if (Array.isArray(value) && field?.required && !value.length) {
      error = ErrorType.REQUIRED;
    } else if (value && field?.pattern && !field?.pattern?.test(value)) {
      error = ErrorType.PATTERN;
    }
  } else if (field?.error === ErrorType.CUSTOM) {
    error = field?.error;
  }
  return error;
};

const getTimeFormat = (date) => {
  const _date = new Date(date);
  return format(
    addMinutes(_date, _date.getTimezoneOffset()),
    FORM.TIME.UI_FORMAT
  );
};

const getTimeToDateFormat = (timeString) => {
  return parse(timeString, FORM.TIME.UI_FORMAT, new Date());
};

const getServerDateFormat = (date) => {
  if (date) {
    return format(new Date(date), FORM.DATE.SERVER_FORMAT)
  }
  return date;
}

const getUIDateFormat = (date) => {
  if (date) {
    return format(new Date(date), FORM.DATE.UI_FORMAT);
  }
  return date;
};

const formatFileSize = (bytes, decimalPoint = 2) => {
  if (bytes === 0) return "0 Bytes";
  var k = 1000,
    dm = decimalPoint || 2,
    sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const splitBySpace = (string) => {
  if (string) {
    return string.split(" ");
  }
  return [];
};

const validatePhone = (event) => {
  const val = event.target.value;
  let count = 0;

  for (let i = 0; i < val.length; i++)
    if (val.charAt(i) in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) count++;

  const isValid = count === 10 ? "" : true;
  return isValid;
};

const FormUtils = {
  ErrorType,
  getFormValues,
  validateFields,
  validateCommonError,
  getServerDateFormat,
  getUIDateFormat,
  getTimeFormat,
  getTimeToDateFormat,
  getFormValuesWithPayload,
  formatFileSize,
  splitBySpace,
  validatePhone,
};
export default FormUtils;
