import React, { createContext, useContext, useState } from 'react';

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
    const [userPermissions, setUserPermissions] = useState({
        role: "",
        permissions: []
    });

    const setPermissions = (permissions) => {
        setUserPermissions(permissions);
    };

    return (
        <PermissionsContext.Provider value={{ userPermissions, setPermissions }}>
            {children}
        </PermissionsContext.Provider>
    );
};

export const usePermissions = () => {
    return useContext(PermissionsContext);
};
