import { CertificationIcon, CredentialsIcon, CustomersIcon, EmployeesIcon, EquipmentIcon, LocationIcon, PPEIcon, ProjectsIcon, SkillsIcon, TeamsIcon, TrainingsIcon } from "../components/Icon/Icon";

export const credentialTypeList = [
  {
    value: "MANDATE",
    label: "Mandate",
  },
  {
    value: "CERTIFICATION",
    label: "Certification",
  },
  {
    value: "TRAINING_CERTIFICATE",
    label: "Training Certificate",
  },
];

export const employeesCredentialType = [
  {
    value: "NEW",
    label: "New",
  },
  {
    value: "EXISTING",
    label: "Existing",
  }]
  
export const shiftTypeList = [
  {
    value: "STANDARD_SHIFT",
    label: "Standard Shift",
  },
  {
    value: "DAY_SHIFT",
    label: "Day Shift",
  },
  {
    value: "NIGHT_SHIFT",
    label: "Night Shift",
  },
];

export const TrainingTypeList = [
  {
    value: 1,
    label: " In House Training",
  },
  {
    value: 2,
    label: "On Job Training",
  },
  {
    value: 3,
    label: "Third Party Training",
  },
];

export const TrainingSubTypeList = [
  {
    value: 1,
    label: " In House Training",
  },
  {
    value: 2,
    label: "On Job Training",
  },
  {
    value: 3,
    label: "Third Party Training",
  },
];

export const durationlist = [
  {
    value: 1,
    label: " weeks",
  },
  {
    value: 2,
    label: "months",
  },
  {
    value: 3,
    label: "year",
  },

];



export const searchJsonData = [
  { title: "India", continent: "Asia" },
  { title: "New Zealand", continent: "Australasia" },
  { title: "South Africa", continent: "Africa" },
  { title: "Usa", continent: "North America" },
  { title: "France", continent: "Europe" },
];

export const optionData = [
  "Oliver",
  "Van Henry",
  "April Tucker",
  "Ralph",
  "Alexander",
  "Carlos",
  "Miriam",
  "Bradley ",
  "Virginia",
  "Kelly ",
];

export const menuData = [
  {
    id: 1,
    name: "Customers",
    route: "/customers",
    icon: <CustomersIcon />,
  },
  {
    id: 2,
    name: "Locations",
    route: "/locations",
    icon: <LocationIcon />,
  },
  {
    id: 3,
    name: "Employees",
    route: "/employees",
    icon: <EmployeesIcon />,
  },
  {
    id: 4,
    name: "Credentials",
    route: "/credential",
    icon: <CredentialsIcon />
  },
  {
    id: 5,
    name: "Projects",
    route: "/projects",
    icon: <ProjectsIcon />
  },
  {
    id: 6,
    name: "Teams View",
    route: "/teams",
    icon: <TeamsIcon />
  },
  {
    id: 7,
    name: "Equipments",
    route: "/equipment",
    icon: <EquipmentIcon />
  },
  {
    id: 8,
    name: "Certifications",
    route: "/certification",
    icon: <CertificationIcon />
  },
  {
    id: 9,
    name: "Skills",
    route: "/skill",
    icon: <SkillsIcon />
  },
  {
    id: 10,
    name: "Trainings",
    route: "/training",
    icon: <TrainingsIcon />
  },
  {
    id: 11,
    name: "PPE",
    route: "/ppe",
    icon: <PPEIcon />
  },
  // {
  //   id: 12,
  //   name: "Roles",
  //   route: "/roles",
  //   icon: <RoleIcon />
  // },
];

export const tableData = [
  {
    headerName: "Athlete Details",
    children: [
      {
        field: "athlete",
        width: 180,
        filter: "agTextColumnFilter",
      },
      {
        field: "age",
        width: 90,
        filter: "agNumberColumnFilter",
      },
      { headerName: "Country", field: "country", width: 140 },
    ],
  },
  {
    headerName: "Details",
    children: [
      {
        field: "athlete",
        width: 180,
        filter: "agTextColumnFilter",
      },
      {
        field: "age",
        width: 90,
        filter: "agNumberColumnFilter",
      },
      { headerName: "Country", field: "country", width: 140 },
    ],
  },
  {
    headerName: "Sports Results",
    children: [
      { field: "sport", width: 140 },
      {
        columnGroupShow: "closed",
        field: "total",
        width: 100,
        filter: "agNumberColumnFilter",
      },
    ],
  },
  {
    headerName: "Sports Results",
    children: [
      { field: "sport", width: 140 },
      {
        columnGroupShow: "closed",
        field: "total",
        width: 100,
        filter: "agNumberColumnFilter",
      },
    ],
  },
  {
    headerName: "Sports Results",
    children: [
      { field: "sport", width: 140 },
      {
        columnGroupShow: "closed",
        field: "total",
        width: 100,
        filter: "agNumberColumnFilter",
      },
    ],
  },
];

export const SingleColumnDataTable = [
  { field: "name", headerName: "Credential Name" },
  {
    field: "type",
    headerName: "Credential Type",
    initialWidth: 120,
  },
  { field: "AuthName", headerName: "Issuing Authority Name" },
  { field: "trainingname", headerName: "Training Period" },
  {
    field: "validity",
    headerName: "Validity / Expiration / Retraining",
  },
  {
    field: "attechment",
    headerName: "Attachment",
  },
  {
    headerName: "Sports Results",
  },
];

export const rowDatas = [
  {
    id: 1,
    name: "Credential Name A",
    type: "Background Check",
    AuthName: "Issuing Authority Name A",
    trainingname: "2 Weeks",
    validity: "12 Weeks",
    attechment: "Yes",
  },
  {
    id: 2,
    name: "Credential Name B",
    type: "Check",
    AuthName: "Issuing Authority Name B",
    trainingname: "22 Weeks",
    validity: "32 Weeks",
    attechment: "Yes",
  },
  {
    id: 3,
    name: "Credential Name C",
    type: "Drug Screen",
    AuthName: "Issuing Authority Name C",
    trainingname: "12 Weeks",
    validity: "15 Weeks",
    attechment: "Yes",
  },
  {
    id: 4,
    name: "Credential Name D",
    type: "Awareness Training",
    AuthName: "Issuing Authority Name D",
    trainingname: "15 Weeks",
    validity: "18 Weeks",
    attechment: "Yes",
  },
  {
    id: 5,
    name: "Credential Name E",
    type: "Awareness Training",
    AuthName: "Issuing Authority Name E",
    trainingname: "20 Weeks",
    validity: "4 Weeks",
    attechment: "Yes",
  },
];

export const CustomerData = [
  {
    id: "7d6d51db-e68c-4629-a0ba-575560f584cd",
    name: "ramana",
    contact: {
      firstName: "fgbftrg",
      lastName: "fghgfh",
      phone: "hfgfghh",
      email: "rewrewrte",
    },
    address: {
      address1: "sDGFDG",
      address2: "dfsgdfg",
      city: "greqertg",
      state: "treterqt",
      zip: "sdvfsdfdsf",
    },
  },
  {
    id: "16f2e114-35c9-452b-a142-f7d06d893dd2",
    name: "ramana",
    contact: {
      firstName: "fgbftrg",
      lastName: "fghgfh",
      phone: "hfgfghh",
      email: "rewrewrte",
    },
    address: {
      address1: "manvitha",
      address2: "yrapn",
      city: "hyd",
      state: "tel",
      zip: "dhssfgd",
    },
  },
  {
    id: "19d6a5ee-3bde-46b9-ac8c-ba0efbff20a6",
    name: "balu",
    contact: {
      firstName: "fgbftrg",
      lastName: "fghgfh",
      phone: "hfgfghh",
      email: "rewrewrte",
    },
    address: {
      address1: "jfadjlkfadlkjf",
      address2: "sd,asjfsdjfd",
      city: "djfsdjfdljf",
      state: "nsdkasdlkd",
      zip: "sdmbs,djsdjf",
    },
  },
  {
    id: "1a4fc9e9-f0df-4e2d-a5b0-d3709fddee76",
    name: "ncompass",
    contact: {
      firstName: "fgbftrg",
      lastName: "fghgfh",
      phone: "hfgfghh",
      email: "rewrewrte",
    },
    address: {
      address1: "dfFFDFDSFsdfsd",
      address2: "wfwetwegsdfd",
      city: "fwewwv",
      state: "huryweui",
      zip: "mnfkjfhldifh",
    },
  },
  {
    id: "3351a0e7-fe23-4e19-ba43-f49f1bb657f7",
    name: "ramana",
    contact: {
      firstName: "fgbftrg",
      lastName: "fghgfh",
      phone: "hfgfghh",
      email: "rewrewrte",
    },
    address: {
      address1: "fsljfFG",
      address2: "nsdfsdfsd",
      city: "wfwere",
      state: "sdftet",
      zip: "gdfgdf",
    },
  },
  {
    id: "363bd828-724d-4451-819d-ad9e077e52b9",
    name: "nazeer",
    contact: {
      firstName: "fgbftrg",
      lastName: "fghgfh",
      phone: "hfgfghh",
      email: "rewrewrte",
    },
    address: {
      address1: "manvitha",
      address2: "yrapn",
      city: "hyd",
      state: "tel",
      zip: "dhssfgd",
    },
  },
  {
    id: "4a9a0212-cddd-4012-8b50-81b9cdd526cd",
    name: "kavya",
    contact: {
      firstName: "fgbftrg",
      lastName: "fghgfh",
      phone: "hfgfghh",
      email: "rewrewrte",
    },
    address: {
      address1: "deelhi",
      address2: "paradise",
      city: "hyd",
      state: "tel",
      zip: "524001",
    },
  },
  {
    id: "5174936f-1703-4f1b-885c-b8ccf1dd5d9a",
    name: "ramana",
    contact: {
      firstName: "fgbftrg",
      lastName: "fghgfh",
      phone: "hfgfghh",
      email: "rewrewrte",
    },
    address: {
      address1: "fsdfsdfsdfsdfds",
      address2: "dsdgsdgdgdfgfd",
      city: "hjhgj",
      state: "fsdfsdf",
      zip: "efefsdf",
    },
  },
  {
    id: "69313de0-3828-434e-b897-0342577a21e2",
    name: "pavan",
    contact: {
      firstName: "fgbftrg",
      lastName: "fghgfh",
      phone: "hfgfghh",
      email: "rewrewrte",
    },
    address: {
      address1: "egg",
      address2: "fgdfgdfg",
      city: "sggger",
      state: "FSDFSDF",
      zip: "egerterterg",
    },
  },
  {
    id: "1d11bb1a-0361-4f99-b661-adf5874c5143",
    name: "kavya",
    contact: {
      firstName: "fgbftrg",
      lastName: "fghgfh",
      phone: "hfgfghh",
      email: "rewrewrte",
    },
    address: {
      address1: "jfadjlkfadlkjf",
      address2: "sd,asjfsdjfd",
      city: "djfsdjfdljf",
      state: "nsdkasdlkd",
      zip: "sdmbs,djsdjf",
    },
  },
];
export const EmployeeMockData = [
  {
    id: "1",
    name: "Employee Name 1",
    platformUser: {
      firstName: "Name11",
      lastName: "Surnam1 1",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 1",
      state: "State11",
      zip: "Zip 1",
    },
    contact: {
      phone: "Phone 1",
      email: "Emai1 2",
    }
  },
  {
    id: "2",
    name: "Employee Name 2",
    platformUser: {
      firstName: "Name21",
      lastName: "Surnam2 1",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 2",
      state: "State21",
      zip: "Zip 2",
    },
    contact: {
      phone: "Phone 2",
      email: "Email 2",
    }
  }, {
    id: "3",
    name: "Employee Name 3",
    platformUser: {
      firstName: "Name31",
      lastName: "Surnam3 1",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 3",
      state: "State31",
      zip: "Zip 3",
    },
    contact: {
      phone: "Phone 3",
      email: "Email 3",
    }
  }, {
    id: "4",
    name: "Employee Name 4",
    platformUser: {
      firstName: "Name41",
      lastName: "Surnam4 1",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 4",
      state: "State41",
      zip: "Zip 4",
    },
    contact: {
      phone: "Phone 4",
      email: "Email 4",
    }
  }, {
    id: "5",
    name: "Employee Name 5",
    platformUser: {
      firstName: "Name51",
      lastName: "Surnam5 1",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 5",
      state: "State51",
      zip: "Zip 5",
    },
    contact: {
      phone: "Phone 5",
      email: "Email 5",
    }
  }, {
    id: "6",
    name: "Employee Name 6",
    platformUser: {
      firstName: "Name61",
      lastName: "Surnam6 1",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 6",
      state: "State61",
      zip: "Zip 6",
    },
    contact: {
      phone: "Phone 6",
      email: "Email 6",
    }
  }, {
    id: "7",
    name: "Employee Name 7",
    platformUser: {
      firstName: "Name71",
      lastName: "Surnam7 1",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 7",
      state: "State71",
      zip: "Zip 7",
    },
    contact: {
      phone: "Phone 7",
      email: "Email 7",
    }
  }, {
    id: "8",
    name: "Employee Name 8",
    platformUser: {
      firstName: "Name81",
      lastName: "Surnam8 1",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 8",
      state: "State81",
      zip: "Zip 8",
    },
    contact: {
      phone: "Phone 8",
      email: "Email 8",
    }
  }, {
    id: "9",
    name: "Employee Name 9",
    platformUser: {
      firstName: "Name91",
      lastName: "Surnam9 1",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 9",
      state: "State91",
      zip: "Zip 9",
    },
    contact: {
      phone: "Phone 9",
      email: "Email 9",
    }
  }, {
    id: "10",
    name: "Employee Name 10",
    platformUser: {
      firstName: "Name 10",
      lastName: "Surname 10",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 10",
      state: "State 10",
      zip: "Zip 10",
    },
    contact: {
      phone: "Phone 10",
      email: "Email 10",
    }
  }, {
    id: "11",
    name: "Employee Name 11",
    platformUser: {
      firstName: "Name 11",
      lastName: "Surname 11",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 11",
      state: "State 11",
      zip: "Zip 11",
    },
    contact: {
      phone: "Phone 11",
      email: "Email 11",
    }
  }, {
    id: "12",
    name: "Employee Name 12",
    platformUser: {
      firstName: "Name 12",
      lastName: "Surname 12",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 12",
      state: "State 12",
      zip: "Zip 12",
    },
    contact: {
      phone: "Phone 12",
      email: "Email 12",
    }
  }, {
    id: "13",
    name: "Employee Name 13",
    platformUser: {
      firstName: "Name 13",
      lastName: "Surname 13",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 13",
      state: "State 13",
      zip: "Zip 13",
    },
    contact: {
      phone: "Phone 13",
      email: "Email 13",
    }
  }, {
    id: "14",
    name: "Employee Name 14",
    platformUser: {
      firstName: "Name 14",
      lastName: "Surname 14",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 14",
      state: "State 14",
      zip: "Zip 14",
    },
    contact: {
      phone: "Phone 14",
      email: "Email 14",
    }
  }, {
    id: "15",
    name: "Employee Name 15",
    platformUser: {
      firstName: "Name 15",
      lastName: "Surname 15",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 15",
      state: "State 15",
      zip: "Zip 15",
    },
    contact: {
      phone: "Phone 15",
      email: "Email 15",
    }
  }, {
    id: "16",
    name: "Employee Name 16",
    platformUser: {
      firstName: "Name 16",
      lastName: "Surname 16",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 16",
      state: "State 16",
      zip: "Zip 16",
    },
    contact: {
      phone: "Phone 16",
      email: "Email 16",
    }
  }, {
    id: "17",
    name: "Employee Name 17",
    platformUser: {
      firstName: "Name 17",
      lastName: "Surname 17",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 17",
      state: "State 17",
      zip: "Zip 17",
    },
    contact: {
      phone: "Phone 17",
      email: "Email 17",
    }
  }, {
    id: "18",
    name: "Employee Name 18",
    platformUser: {
      firstName: "Name 18",
      lastName: "Surname 18",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 18",
      state: "State 18",
      zip: "Zip 18",
    },
    contact: {
      phone: "Phone 18",
      email: "Email 18",
    }
  }, {
    id: "19",
    name: "Employee Name 19",
    platformUser: {
      firstName: "Name 19",
      lastName: "Surname 19",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 19",
      state: "State 19",
      zip: "Zip 19",
    },
    contact: {
      phone: "Phone 19",
      email: "Email 19",
    }
  }, {
    id: "20",
    name: "Employee Name 20",
    platformUser: {
      firstName: "Name 20",
      lastName: "Surname 20",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 20",
      state: "State 20",
      zip: "Zip 20",
    },
    contact: {
      phone: "Phone 20",
      email: "Email 20",
    }
  }, {
    id: "21",
    name: "Employee Name 21",
    platformUser: {
      firstName: "Name 21",
      lastName: "Surname 21",
      id: "",
    },
    address: {
      address1: "Address 1",
      address2: "Address 2",
      city: "City 21",
      state: "State 21",
      zip: "Zip 21",
    },
    contact: {
      phone: "Phone 21",
      email: "Email 21",
    }
  }
];

export const LocationMockData = [
  {
    id: "1",
    name: "Name A",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "A",
      city: "City Name A",
      state: "State  Name A",
      zip: "Zip Code A",
      country: "Country Name A",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "2",
    name: "Name B",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "A",
      city: "City Name B",
      state: "State  Name B",
      zip: "Zip Code B",
      country: "Country Name B",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "3",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "4",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "5",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "6",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "7",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "8",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "9",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "10",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "11",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "12",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "13",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "14",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "15",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "16",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "17",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "18",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  }, {
    id: "19",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "20",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "21",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "22",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "23",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "24",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },
  {
    id: "25",
    name: "Name C",
    addressId: "ID",
    address: {
      id: "ID",
      address1: "Name",
      address2: "C",
      city: "City Name C",
      state: "State  Name C",
      zip: "Zip Code C",
      country: "Country Name C",
      createdBy: "ID",
      createdDate: "DateTime",
      modifiedBy: "UUID",
      modifiedDate: "DateTime",
    },
    createdBy: "ID",
    createdDate: "DateTime",
    modifiedBy: "UUID",
    modifiedDate: "DateTime",
  },


];