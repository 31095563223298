import React, { useEffect } from "react";
import Layout from "./components/Layout";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Typography, Backdrop, Grid } from "@mui/material";
import LoadingImage from "./assets/loader.gif";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ApolloInterceptor from "./services/ApolloClient/Interceptor";
import { onError } from "@apollo/client/link/error";
import { LoaderUtils } from "./services/Utils";
import { toast } from "react-toastify";
import { ApolloClient, ApolloLink, ApolloProvider, Observable, from, fromPromise } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { initializeAppInsights } from './appInsightsConfig';
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { cloneDeep } from "lodash";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { CatchLink, onCatch } from "./services/ApolloClient/CatchLink";

const theme = createTheme({
  palette: {
    text: {
      primary: "rgba(0, 0, 0, 1)",
      secondary: "rgba(0, 0, 0, 0.8)",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "OpenSans",
    },
  },
});

function App() {

  const {
    getAccessTokenSilently,
    logout,
    loginWithRedirect,
    isAuthenticated,
    isLoading,
  } = useAuth0();

  const reqResLink = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    const { header } = operation.getContext();

    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      logout();
      return;
    }
    operation.setContext(({ headers = {} }) => {
      LoaderUtils.increaseAPICount();
      return {
        headers: {
          ...headers,
          ...header,
          authorization: `Bearer ${accessToken}` || null,
        }
      };

    });
    return forward(operation).map((response) => {
      const { success } = operation.getContext();
      if (success) {
        toast(success, { type: "success" });
      }
      LoaderUtils.decreaseAPICount();
      return response;
    });
  })

  const errorHandlerLink = onError(({ operation, networkError, graphQLErrors, forward }) => {
    const { error = "Something went wrong" } = operation.getContext();
    LoaderUtils.decreaseAPICount();
    if (window?.location?.hostname === 'dev.planitindustry.com') {
      const appInsights = initializeAppInsights();
      appInsights.trackEvent({
        name: networkError, properties: {
          operationName: operation?.operationName,
          graphQLErrors: graphQLErrors,
        }
      })
      appInsights.trackException({ error: new Error(networkError?.statusCode), severityLevel: SeverityLevel.Error })
    }

    switch (networkError?.statusCode) {
      case 401:
        return fromPromise(
          getAccessTokenSilently().catch(() => {
            logout();
            return;
          })
        )
          .filter(value => Boolean(value))
          .flatMap(accessToken => {
            localStorage.setItem("access_token", accessToken)
            return forward(operation);
          });
        break;
      case 200:
        if (Array.isArray(graphQLErrors) && graphQLErrors.length && graphQLErrors[0].message) {
          toast(graphQLErrors[0].message, { type: "error" });
        } else {
          toast("Oops, Server isn't responding", { type: "error" });
        }
        break;
      case 500:
        if (Array.isArray(graphQLErrors) && graphQLErrors.length && graphQLErrors[0].message) {
          if (graphQLErrors && graphQLErrors[0]?.path && (graphQLErrors[0]?.path[0] === "paymentInfo" || graphQLErrors[0]?.path[0] === "BillingInfo")) {
            toast.error('Something went wrong.')
          }
          else if (graphQLErrors && graphQLErrors[0]?.message && graphQLErrors[0].message.includes("https://support.stripe.com")) {
            toast.error('Something went wrong.')
          }
          else {
            toast(graphQLErrors[0].message, { type: "error" });
          }
        } else {
          toast("Oops, Server isn't responding", { type: "error" });
        }
        break;

      default:
        toast(error, { type: "error" });
        break;
    }
  })

  const apolloClient = new ApolloClient({
    link: from([errorHandlerLink, ApolloInterceptor.links.abortLink, reqResLink, ApolloInterceptor.links.uploadLink]),
    typeDefs: ApolloInterceptor.typeDefs,
    cache: ApolloInterceptor.cache,
    resolvers: ApolloInterceptor.resolvers,
    defaultOptions: ApolloInterceptor.defaultOptions,
  });

  useEffect(() => {
    LoaderUtils.resetAPICount();
  }, []);


  useEffect(() => {
    if (window?.location?.hostname === 'dev.planitindustry.com') {
      const appInsights = initializeAppInsights();

      // Example: Track a custom event
      const trackEvent = () => {
        appInsights.trackEvent({ name: 'CustomEvent', properties: { key: 'value' } });
      };

      // Track custom event when the component mounts
      trackEvent();

      // Clean up when the component unmounts
      return () => {
        appInsights.flush();
      };
    }
  }, []);

  if (!localStorage.getItem("access_token") && !isLoading && !isAuthenticated) {
    loginWithRedirect();
    return <Backdrop open={true}>
      <Grid container display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <Grid container height={"100vh"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
          <img
            className="loading-indicator"
            height={"80px"}
            width={"80px"}
            alt="loading-indicator"
            src={LoadingImage}
          />
        </Grid>
      </Grid>
    </Backdrop>
  }

  return (
    <div className="App" data-testid="renders learn react link">
      <AppInsightsErrorBoundary onError={() => <h1>Something went wrong from appInsights</h1>} appInsights={initializeAppInsights}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            <Box id="loading-container" className="loading-wrapper">
              <img
                className="loading-indicator"
                height={"80px"}
                width={"80px"}
                alt="loading-indicator"
                src={LoadingImage}
              />
            </Box>
            <Typography variant=""></Typography>
            {(localStorage.getItem("access_token") || isAuthenticated) && <Layout />}
          </ApolloProvider>
        </ThemeProvider>
      </AppInsightsErrorBoundary >
    </div>
  );
}
export default App;
