import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/index.scss";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router } from "react-router-dom";
import { registerLicense } from '@syncfusion/ej2-base'
import { PermissionsProvider } from './PermissionsContext';

registerLicense("ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmJPYVF2R2BJeVRzcV9FY0wgOX1dQl9gSH9ScEVlWndeeHZSR2M")

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Router>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE_URL,
        scope:
          "openid profile email phone read:current_user update:current_user_metadata",
      }}
    >
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <PermissionsProvider>
      <App />
      </PermissionsProvider>
    </Auth0Provider>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
