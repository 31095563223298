import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Divider } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PlanitLogoLight } from "../../assets/svg/index";
import AccountMenu from "../../auth/AccountMenu";
import { Drawer, MenuToggle } from "../../styledComponents/MainMenu.styled";
import {
  ListItemButtons,
  ListItemIcons,
  ListItemTexts,
  ListItems,
  Lists,
  ResetListItems,
} from "../../styledComponents/MainMenuLayout.styled";
import { menuData } from "./../../services/mockData";

export default function MainMenu() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(true);
  const [pathname, setPathname] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const HandleClick = (event) => {
    navigate(event?.route);
    // window.location.href = event?.route;
  };

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    width < 625 && handleDrawerClose();
  }, [width]);

  useEffect(() => {
    setPathname(location.pathname)
  }, [location.pathname]);

  const sideMenuContainerStyle = {
    overflowY: 'auto',
    maxHeight: 'calc(100vh - 84px)',
    width: '100%',
    scrollbarWidth: 'thin',
    scrollbarColor: 'transparent transparent',
    overflowX: 'hidden'
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      theme={theme}
      className="main-menu"
    >
      <MenuToggle className="menu-toggle" onClick={() => setOpen(!open)} >
        {open ? <ArrowBackIosNewIcon /> : <ArrowForwardIosIcon />}
      </MenuToggle>
      <Lists>
        <ResetListItems>
          <Link to="/" aria-label="Planit Logo Light" onClick={() => window.location.href = "/"}>
            <PlanitLogoLight />
          </Link>
        </ResetListItems>
        <div style={sideMenuContainerStyle}>
          {menuData?.map((items, index) => {
            return (
              <>
                <ListItems
                  id={items?.id}
                  disablePadding
                  onClick={(event) => HandleClick(items)}
                  sx={{ width: !open ? "53px !important" : "" }}
                  key={items?.id}
                  className={pathname.includes(items.route) ? "active active-side-menu" : "side-menu"}
                  active={pathname.includes(items.route)}
                  open={open}
                >
                  <ListItemButtons
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcons 
                    // sx={{ mr: open ? 1 : "auto" }} 
                    key={index}>
                      {
                        items?.icon && React.createElement(items?.icon.type, { ...items?.icon?.props, color: pathname.includes(items.route) ? "#305496" : "#E2E0E0" })
                      }
                    </ListItemIcons>
                    <ListItemTexts
                      id={items?.id}
                      primary={items?.name}
                      sx={{ opacity: open ? 1 : 0 }}
                      active={pathname.includes(items.route)}
                    />
                  </ListItemButtons>
                </ListItems>
                {(items?.id === 2 || items?.id === 6) && (
                  <ResetListItems sx={{ width: !open ? "53px" : "165px" }}>
                    <Divider />
                  </ResetListItems>
                )}
              </ >
            );
          })}
        </div>
      </Lists>
      <Box>
        <AccountMenu IsMenuopen={open} />
      </Box>
    </Drawer>
  );
}
