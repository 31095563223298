import { useAuth0 } from "@auth0/auth0-react";
import Logout from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import { Avatar, Box, Menu, MenuItem, Tooltip } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { usePermissions } from '../PermissionsContext';

export default function AccountMenu({ IsMenuopen }) {
    const { user, isAuthenticated, logout } = useAuth0();
    const { setPermissions, userPermissions } = usePermissions();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

  useEffect(() => {
        if (user) {
            const userId = user[`${process.env.REACT_APP_AUTH_URL}UserId`];
            const tenantId = user[`${process.env.REACT_APP_AUTH_URL}tenantId`];
            const role = user[`${process.env.REACT_APP_AUTH_URL}role`][0];
            const permissions = user[`${process.env.REACT_APP_AUTH_URL}permissions`];
            const subscription = user[`${process.env.REACT_APP_AUTH_URL}OrganizationSubsriptionDetails`];
          const sub = user?.sub;
            setPermissions({
                userId,
                tenantId,
                role,
                permissions,
              subscription,
              sub
            });
          localStorage.setItem("role", role);
        }
    }, [user]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (route) => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
      localStorage.clear();
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    return (
      <React.Fragment>
        <Box
          sx={{ display: "flex", alignItems: "center", textAlign: "center" }}
        >
          <Tooltip title="Settings">
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{
                p: "12px",
                color: "rgb(226, 224, 224)",
                borderRadius: 0,
                width: "100%",
                height: "60px",
                justifyContent: "flex-start",
                fontFamily: "OpenSans",
                fontSize: "1rem",
                boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.2)'
              }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ marginRight: 2 }}
                src={user?.picture}
                alt={user?.name}
              />
              {IsMenuopen && "Settings"}
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                bottom: 10,
                left: 0,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateX(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        > 
          <MenuItem onClick={handleClose} component={Link} to="/my-profile">
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            My Profile
          </MenuItem>

          {(localStorage.getItem("role") === "Tenant" || userPermissions?.role === "Tenant") && (
            <Fragment>
              <MenuItem onClick={handleClose} component={Link} to="/my-team">
                <ListItemIcon>
                  <PeopleAltIcon fontSize="small" />
                </ListItemIcon>
                My Teams
              </MenuItem>
              <MenuItem onClick={handleClose} component={Link} to="/roles">
                <ListItemIcon>
                  <ManageAccountsIcon fontSize="small" />
                </ListItemIcon>
                Roles and Permission
              </MenuItem>
            </Fragment>
          )}
          {(localStorage.getItem("role") === "Tenant" || userPermissions?.role === "Tenant") && (
            <Fragment>
              <MenuItem onClick={handleClose} component={Link} to="/subscriptions">
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                Subscription & Add-ons
              </MenuItem>
            </Fragment>
          )}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
}