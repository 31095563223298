const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === "desc"
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const filterTable = (order, orderBy, page, rowsPerPage, rows) => stableSort(rows, getComparator(order, orderBy)).slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
)

function sortArrayByProperty(array, propertyName, order = 'asc') {
    if (!array || !array.length) {
        return [];
    }

    const compareFunction = (a, b) => {
        const propA = a[propertyName];
        const propB = b[propertyName];

        if (propA < propB) {
            return order === 'asc' ? -1 : 1;
        }
        if (propA > propB) {
            return order === 'asc' ? 1 : -1;
        }
        return 0;
    };

    return array.slice().sort(compareFunction);
}

const TableUtils = { descendingComparator, getComparator, stableSort, filterTable, sortArrayByProperty };
export default TableUtils;