const changePage = (e, URL) => {
    e.preventDefault();
    // Perform any other necessary actions before leaving the page.
    if (URL) {
        window.location.href = URL;
    }
}

const flattenColumns = (columns) => {
    return columns.reduce((acc, column) => {
      if (column.columns) {
        return acc.concat(flattenColumns(column.columns));
      }
      return acc.concat(column);
    }, []);
  };

const OtherUtils = {
    changePage, flattenColumns
};
export default OtherUtils;