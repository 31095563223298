const address = new RegExp(/[0-9a-zA-Z]{6,}/);

export const FORM = {
  PASS: {
    PATTERN: new RegExp(/.*/),
    ERROR: "",
  },
  NAME: {
    PATTERN: new RegExp(/^[a-zA-Z]([a-zA-Z0-9]*\s?){1,}[a-zA-Z0-9]+\s*$/),
    ERROR: "Enter vaild Name",
  },
  EMAIL: {
    PATTERN: new RegExp(
      /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i
    ),
    ERROR: "Enter vaild Email",
  },
  NUMBER: {
    PATTERN: new RegExp(/^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i),
    ERROR: "Enter vaild Number",
  },
  ADDRESS: {
    PATTERN: new RegExp(/[0-9a-zA-Z]{2,}/),
    ERROR: "Enter vaild Address",
  },
  CITY: {
    PATTERN: new RegExp(/^((([a-zA-Z])+\s?){2,})*$/),
    ERROR: "Enter vaild City",
  },
  STATE: {
    PATTERN: address,
    ERROR: "Enter vaild State",
  },
  ZIP: {
    PATTERN: new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/),
    ERROR: "Enter vaild Zip Code",
  },
  ONLY_NUMBER: {
    PATTERN: new RegExp(/^[+]?([0-9]*)$/),
    ERROR: "Enter valid number",
  },
  TIME_NUMBER: {
    PATTERN: new RegExp(/^([1-9]|[1-9][0-9]|[1-2][0-9][0-9]|3[0-5][0-9]|36[0-5])$/),
    ERROR: "Enter valid number",
  },
  DECIMAL_NUMBER: {
    PATTERN: new RegExp(/^\d+(\.\d+)?$/),
    ERROR: "Enter valid number",
  },
  ALPHA_NUMERIC: {
    PATTERN: new RegExp(/[0-9a-zA-Z]{2,}/),
    ERROR: "Enter vaild data",
  },
  PHONE: {
    PATTERN: new RegExp(/^\(\d{3}\) \d{3}-\d{4}$/),
    ERROR: "Enter vaild Phone Number",
  },
  DATE: {
    PATTERN: new RegExp(
      /^(0[1-9]|1[0-2])-(19|20)\d{2}-(0[1-9]|1\d|2\d|3[01])$/
    ),
    UI_FORMAT: "MM-dd-yyyy",
    UI_FORMAT2: "MM-DD-YYYY",
    SERVER_FORMAT: "yyyy-MM-dd HH:mm:ss.SSSSx",
    ERROR: "Enter valid Date",
  },
  TIME: {
    UI_FORMAT: "HH:mm",
  },
  PASSWORD:{
    PATTERN: new RegExp(/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*]).{8,50}$/),
    ERROR: "Make sure the password has atleast 8 characters long with a capital case letter, small case letter, is both alphanumeric and has a special character"
  }
};

export const EXPIRING_SOON_DAY_DIFFERENCE = 14;