import { Box } from "@mui/material";
import styled from "styled-components";
import { alpha } from '@mui/material/styles';

export const StatusInfoChip = styled(Box)(({ color }) => ({
    border: "1.5px solid",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    width: "fit-content",
    padding: "3px 5px 2px 7px",
    backgroundColor: alpha(color, 0.1),
}));


export const StatusCountChip = styled(Box)(({ color }) =>({
    border: "1.5px solid",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    width: "fit-content",
    padding: "1px 5px",
    backgroundColor: alpha(color, 0.1),
}));