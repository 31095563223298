import MuiDrawer from '@mui/material/Drawer';
import styled from "styled-components";
import Box from "@mui/material/Box";
const drawerWidth = 180;
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme?.transitions?.create('width', {
        easing: theme?.transitions?.easing.sharp,
        duration: theme?.transitions?.duration?.enteringScreen,
    }),
    // overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme?.transitions?.create('width', {
        easing: theme?.transitions?.easing?.sharp,
        duration: theme?.transitions?.duration?.leavingScreen,
    }),
    // overflowX: 'hidden',
    width: `calc(${theme?.spacing(7)} + 1px)`,
    [theme?.breakpoints?.up('sm')]: {
        width: `calc(${theme?.spacing(8)} + 1px)`,
    },
});

const generatedDefaultClasses = () => ({
    backgroundColor: '#305496 !important',
});

export const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        position: "relative",
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...generatedDefaultClasses(theme),
            '& MuiDrawer-paperAnchorLeft': generatedDefaultClasses(theme),
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...generatedDefaultClasses(),
            '& MuiDrawer-paperAnchorLeft': generatedDefaultClasses(theme),
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),

);

export const MenuToggle = styled(Box)`
font-size:12px;
  background: #fff;
  padding: 2px 7px;
  right: -16px;
  top: 4px;
  position: absolute;
  border-radius:50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1;
`;
