import React from "react";
import { useAuth0 } from "@auth0/auth0-react";


// Landingpage
function Landingpage() {
  const { isAuthenticated } = useAuth0();
  return isAuthenticated && <p>Cooming Soon</p>;
}
export default Landingpage;
