const showLoader = () => {
    document.getElementById('loading-container')?.classList?.add("show");
}

const hideLoader = () => {
    const apiCount = parseInt(localStorage.getItem("apiCount")) || 0;
    if (apiCount === 0) { // count will be stored in string
        document.getElementById('loading-container')?.classList?.remove("show");
    }
}

const increaseAPICount = () => {
    let apiCount = parseInt(localStorage.getItem("apiCount")) || 0;
    localStorage.setItem("apiCount", ++apiCount);
    showLoader();
}

const decreaseAPICount = () => {
    let apiCount = parseInt(localStorage.getItem("apiCount")) || 0;
    if (apiCount > 0) {
        localStorage.setItem("apiCount", --apiCount)
    }
    hideLoader();
}

const resetAPICount = () => {
    localStorage.setItem("apiCount", 0);
}

const LoaderUtils = { increaseAPICount, decreaseAPICount, showLoader, hideLoader, resetAPICount };

export default LoaderUtils;
