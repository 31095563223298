import { Chip, Typography } from "@mui/material"
import { StatusInfoChip, StatusCountChip } from "../../styledComponents/StatusChip"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const getStatus = (status) => {
    switch (status) {
        case "VALID":
            return <StatusInfoChip color="#03BE00">
                <Typography fontSize="x-small" color={"#03BE00"}>Valid</Typography>
                <InfoOutlinedIcon fontSize="12px" color="success" />
            </StatusInfoChip>
        case "AVAILABLE":
            return <StatusInfoChip color="#03BE00">
                <Typography fontSize="x-small" color={"#03BE00"}>Available</Typography>
                <InfoOutlinedIcon fontSize="12px" color="success" />
            </StatusInfoChip>
        case "EXPIRED":
            return <StatusInfoChip color="#DF0000">
                <Typography fontSize="x-small" color={"#DF0000"}>Expired</Typography>
                <InfoOutlinedIcon fontSize="12px" color="error" />
            </StatusInfoChip>
        case "UN_AVAILABLE":
            return <StatusInfoChip color="#DF0000">
                <Typography fontSize="x-small" color={"#DF0000"}>Unavailable</Typography>
                <InfoOutlinedIcon fontSize="12px" color="error" />
            </StatusInfoChip>
        case "EXPIRING_SOON":
            return <StatusInfoChip color="#DFBB00">
                <Typography fontSize="x-small" color={"#DFBB00"}>Expiring Soon</Typography>
                <InfoOutlinedIcon fontSize="12px" color="waring" />
            </StatusInfoChip>
        case "PARTIALLY_AVAILABLE":
            return <StatusInfoChip color="#DFBB00">
                <Typography fontSize="x-small" color={"#DFBB00"}>Partially Available</Typography>
                <InfoOutlinedIcon fontSize="12px" color="waring" />
            </StatusInfoChip>
        case "IN_PROGRESS":
            return <StatusInfoChip color="#0288d1">
                <Typography fontSize="x-small" color={"#0288d1"}>In Progress</Typography>
                <InfoOutlinedIcon fontSize="12px" color="info" />
            </StatusInfoChip>
        case "MISSING":
            return <StatusInfoChip color="#3A3A3A">
                <Typography fontSize="x-small" color={"#3A3A3A"}>Missing</Typography>
                <InfoOutlinedIcon fontSize="12px" color="action" />
            </StatusInfoChip>
        case "ACTIVE":
            return <StatusInfoChip color="#03BE00">
                <Typography fontSize="x-small" color={"#03BE00"}>Active</Typography>
                <InfoOutlinedIcon fontSize="12px" color="success" />
            </StatusInfoChip>
        case "IN_ACTIVE":
            return <StatusInfoChip color="#DF0000">
                <Typography fontSize="x-small" color={"#DF0000"}>Inactive</Typography>
                <InfoOutlinedIcon fontSize="12px" color="error" />
            </StatusInfoChip>
        case "INVITED":
            return <StatusInfoChip color="#DFBB00">
                <Typography fontSize="x-small" color={"#DFBB00"}>Pending Invitation</Typography>
                <InfoOutlinedIcon fontSize="12px" color="waring" />
            </StatusInfoChip>
        default:
            break;
    }
}

const getStatusWithCount = (count, status) => {
    switch (status) {
        case "VALID":
            return <StatusCountChip color="#03BE00">
                <Typography fontSize="x-small" color={"#03BE00"}>{count} Valid</Typography>
            </StatusCountChip>
        case "EXPIRED":
            return <StatusCountChip color="#DF0000">
                <Typography fontSize="x-small" color={"#DF0000"}>{count} Expired</Typography>
            </StatusCountChip>
        case "EXPIRING_SOON":
            return <StatusCountChip color="#DFBB00">
                <Typography fontSize="x-small" color={"#DFBB00"}>{count} Expiring Soon</Typography>
            </StatusCountChip>
        case "IN_PROGRESS":
            return <StatusCountChip color="#0288d1">
                <Typography fontSize="x-small" color={"#0288d1"}>{count} In Progress</Typography>
            </StatusCountChip>
        case "MISSING":
            return <StatusCountChip color="#3A3A3A">
                <Typography fontSize="x-small" color={"#3A3A3A"}>{count} Missing</Typography>
            </StatusCountChip>
        default:
            break;
    }
}

const getPaymentStatus = (status) =>{
    switch (status) {
        case "PAID":
            return <StatusInfoChip color="#03BE00">
                <Typography fontSize="x-small" color={"#03BE00"}>{"Completed"}</Typography>
            </StatusInfoChip>
        case "FAILED":
            return <StatusInfoChip color="#DF0000">
                <Typography fontSize="x-small" color={"#DF0000"}>{"Payment failed"}</Typography>
            </StatusInfoChip>
        default:
            break;
    }
}

const UIUtils = { getStatus, getStatusWithCount, getPaymentStatus };
export default UIUtils;