import { formatDistanceToNowStrict } from "date-fns";
import FormUtils from "./FormUtils";

const getAvatar = (name) => {
    if (name) {
        let avatar = "";
        const [firstName, lastName] = name.split(" ");
        if (firstName) {
            avatar = avatar.concat(firstName.charAt(0).toUpperCase());
        }
        if (lastName) {
            avatar = avatar.concat(lastName.charAt(0).toUpperCase());
        }
        return avatar;
    }
    return name;
}

const getMinMaxDates = (dateObjects) => {
    if (!dateObjects || !Array?.isArray(dateObjects) || (Array?.isArray(dateObjects) && !dateObjects.length)) {
        return null;
    }

    let minDate = dateObjects[0]?.startWorkDate ? dateObjects[0]?.startWorkDate : "";
    let maxDate = dateObjects[0]?.estimatedCompletionDate ? dateObjects[0]?.estimatedCompletionDate : "";

    for (let i = 1; i < dateObjects?.length; i++) {
        const currentDateObject = dateObjects[i];
        if (currentDateObject?.startWorkDate < minDate) {
            minDate = currentDateObject?.startWorkDate ? currentDateObject?.startWorkDate : "";
        }
        if (currentDateObject?.estimatedCompletionDate > maxDate) {
            maxDate = currentDateObject?.estimatedCompletionDate ? currentDateObject?.estimatedCompletionDate : "";
        }
    }

    return {
        startWorkDate: minDate,
        estimatedCompletionDate: maxDate
    };
}

const getDateAfterDuration = (durationType, duration, date = new Date()) => {
    switch (durationType) {
        case "MINUTES":
            date.setMinutes(date.getMinutes() + duration);
            break;
        case "HOURS":
            date.setMinutes(date.getMinutes() + (duration * 60));
            break;
        case "DAYS":
            date.setMinutes(date.getMinutes() + (duration * 60 * 24));
            break;
        case "MONTHS":
            date.setMinutes(date.getMinutes() + (duration * 60 * 24 * 30));
            break;
        case "YEARS":
            date.setMinutes(date.getMinutes() + (duration * 60 * 24 * 30 * 12));
            break;

        default:
            break;
    }
    return FormUtils.getServerDateFormat(date);
}

const getTrimmedTitle = (title, trimLocation = "center", charLength = 20) => {

    switch (trimLocation) {
        case "center":
            if (title.length < charLength) {
                return title;
            }
            return title.slice(0, charLength / 2 - 2).concat("...").concat(title.slice(title.length - charLength / 2 + 4, title.length));
        default:
            return title.substr(0, charLength);
    }
}
const getTomorrowDate = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
}

const getBeforeDate = (currentDate = new Date(), beforeDays = 1) => {
    currentDate.setDate(currentDate.getDate() - beforeDays);
    return currentDate;
}

const getAfterDate = (currentDate = new Date(), afterDays = 1) => {
    currentDate.setDate(currentDate.getDate() + afterDays);
    return currentDate;
}

const addOneDayToDate = (date) => {
    const currentDate = new Date(date);
    currentDate.setDate(currentDate.getDate() + 1);
    return currentDate;
}

const isDateExpired = (dateString) => {
    if (!dateString) {
        return true;
    }
    const dateParts = dateString.split('/');
    const inputDate = new Date(`${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`);
    const today = new Date();

    return inputDate < today;
}

const getDistanceToNow = (date) => {
    const difference = formatDistanceToNowStrict(new Date(date), {
        unit: "day", addSuffix: false
    })
    const dayCount = parseInt(difference.split(" ")[0]) ?? 0;
    return dayCount;
}

function getUserActionAndMessage(userStatus) {
    const actions = {
        'ACTIVE': {
            action: "Deactivate",
            message: "Are you sure want to deactivate",
        },
        'IN_ACTIVE': {
            action: "Activate",
            message: "Are you sure want to activate",
        },
        'INVITED': {
            action: "Resend Invite",
            message: "Are you sure want to resend invite to",
        },
    };

    const defaultAction = {
        action: "",
        message: "",
    };

    return actions[userStatus] || defaultAction;
}

const parseDate = (dateString) => {
    if (!dateString || typeof dateString !== 'string') {
      console.error('Invalid date string:', dateString);
      return new Date();
    }
    const [month, day, year] = dateString.split("/");
    return new Date(year, month - 1, day);
  };
  
  const differenceInDays = (date1, date2) => {
    const oneDay = 24 * 60 * 60 * 1000; 
    const diffDays = Math.round(Math.abs((date2 - date1) / oneDay));
    return diffDays;
  };

  const getBillingCycle = (purchasedOn, expiresOn) => {
    const purchaseDate = parseDate(purchasedOn);
    const expirationDate = parseDate(expiresOn);
    const diffDays = differenceInDays(purchaseDate, expirationDate);
  
    switch (true) {
      case (diffDays >= 6 && diffDays <= 8):
        return 'Weekly';
      case (diffDays >= 25 && diffDays <= 31):
        return 'Monthly';
      case (diffDays >= 85 && diffDays <= 95):
        return 'Quarterly';
      case (diffDays >= 170 && diffDays <= 190):
        return 'Half Yearly';
      case (diffDays >= 360 && diffDays <= 366):
        return 'Yearly';
      default:
        return '-';
    }
  };

const isOneMonthOld = (dateString) => {
    const issueDate = new Date(dateString);
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const daysInPreviousMonth = new Date(currentYear, currentMonth, 0).getDate();
    const differenceInMilliseconds = currentDate - issueDate;
    const differenceInDays = differenceInMilliseconds / (1000 * 3600 * 24);
    return differenceInDays >= daysInPreviousMonth;
};

const DataUtils = { getAvatar, getMinMaxDates, getDateAfterDuration, getTomorrowDate, getAfterDate, getBeforeDate, addOneDayToDate, getTrimmedTitle, getDistanceToNow, getUserActionAndMessage, getBillingCycle, isDateExpired, isOneMonthOld };
export default DataUtils;