import { createUploadLink } from "apollo-upload-client";
import { ApolloLink, InMemoryCache, Observable } from "@apollo/client";
import { LoaderUtils } from "../Utils";
import { toast } from "react-toastify";

// import { ProjectSchema, ProjectQueries, ProjectMutations } from "./Mocking/Project"
// import { CustomerSchema, CustomerQueries, } from "./Mocking/Customer"
// import { JobSchema, JobQueries, JobMutations, } from "./Mocking/Job"
// import { CertificateSchema, CertificateQueries, CertificateMutations } from "./Mocking/Certificate"
// import { TrainingMutations, TrainingSchema } from "./Mocking/Training";
// import { CredentialMutations, CredentialSchema } from "./Mocking/Credential";

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

// const httpLink = createHttpLink({
//   uri: process.env.REACT_APP_APOLLO_CLIENT_URL,
// });

/**
 * Reference: https://www.apollographql.com/docs/react/data/file-uploads/
 */
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URL,
});



const abortLink = new ApolloLink((operation, forward) => {
  const controller = new AbortController();
  // const signal = controller.signal;

  const observable = new Observable(observer => {
    if (observer) {

      const subscription = forward(operation).subscribe({
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      });

      return () => {
        controller.abort();
        subscription.unsubscribe();
        if (!operation.getContext().response) {
          LoaderUtils.decreaseAPICount();
          // ToastUtils.showToast("API request cancelled", ToastUtils.TYPE.WARNING);
        }
      };
    }
  });
  return observable;
});


const links = {
  abortLink,
  // reqResLink,
  uploadLink,
}

const typeDefs = [
  // ProjectSchema,
  // CustomerSchema,
  // JobSchema,
  // CertificateSchema,
  // TrainingSchema
];

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    // Project: ProjectQueries,
    // Job: JobQueries,
    // Customer: CustomerQueries,
    // Job: JobQueries,
    // Certificate: CertificateQueries
  },
});

const resolvers = [
  // ProjectMutations,
  // JobMutations,
  // CertificateMutations,
  // TrainingMutations
];

const Interceptor = { links, typeDefs, cache, resolvers, defaultOptions };
export default Interceptor;
