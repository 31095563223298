export function PlanitLogoLight() {
  return (
    <svg
      width="54"
      height="26"
      viewBox="0 0 54 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_0_22)">
        <path
          d="M46.8748 1.13956C48.0792 0.674999 49.3301 0.30335 50.6272 0.117525C51.2758 0.0246135 51.9244 -0.0682988 52.6656 0.0710697C52.8509 0.117525 53.0361 0.163982 53.2678 0.256894C53.3604 0.349807 53.4994 0.396262 53.5458 0.53563C53.6384 0.674999 53.6847 0.814367 53.6847 0.953734C53.6847 1.27893 53.5921 1.46475 53.4994 1.65058C53.4067 1.83641 53.2678 1.97577 53.1288 2.11514C52.6193 2.62615 52.0633 3.04426 51.5074 3.36946C50.9515 3.7411 50.3493 4.06629 49.7933 4.34503C48.6352 4.94897 47.3844 5.41352 46.1799 5.87809C44.9291 6.29619 43.6784 6.66784 42.3812 6.94657C41.7326 7.08594 41.084 7.17886 40.4355 7.27177C39.7869 7.36468 39.092 7.41114 38.3971 7.31823C38.0266 7.27177 37.7023 7.22531 37.2854 6.99303C37.1 6.85366 36.8684 6.57492 36.8684 6.24974C36.8684 5.971 36.9611 5.73872 37.1 5.55289C37.3317 5.2277 37.6096 4.99542 37.8876 4.8096C38.1192 4.62377 38.4435 4.43795 38.7214 4.29858C39.8796 3.64819 41.084 3.13718 42.3349 2.76552C41.223 3.41591 40.0649 4.01984 39.0457 4.76314C38.8141 4.94897 38.5361 5.13478 38.3045 5.36707C38.0728 5.55289 37.8876 5.78517 37.7486 6.01746C37.7023 6.11037 37.656 6.20328 37.656 6.24974C37.656 6.24974 37.656 6.24974 37.7486 6.29619C37.8876 6.38911 38.2119 6.43556 38.4898 6.43556C39.6943 6.43556 40.9451 6.20328 42.1959 5.87809C43.4467 5.59935 44.6512 5.2277 45.8556 4.8096C47.0601 4.39149 48.2646 3.92693 49.3764 3.41591C50.5346 2.90489 51.6927 2.30096 52.5729 1.51121C52.8045 1.32538 52.9435 1.04665 52.9435 1.00019C52.9435 1.00019 52.9435 1.00019 52.8972 0.907283C52.8045 0.860824 52.7118 0.814368 52.5729 0.767912C52.017 0.628543 51.3684 0.628543 50.7662 0.674999C49.469 0.721455 48.1719 0.907283 46.8748 1.13956Z"
          fill="white"
        />
        <path
          d="M16.5277 16.0363V3.03238H18.9095V16.0363H16.5277Z"
          fill="white"
        />
        <path
          d="M32.2483 7.84863C32.9497 6.80471 33.8966 6.40376 35.0189 6.40376C35.8212 6.40376 36.451 6.84505 37.0122 7.36701C37.4885 7.84863 37.7267 8.33026 37.9648 9.39317V16.0363H35.5801V10.4916C35.5801 10.0172 35.4398 9.63748 35.2059 9.3054C34.9722 8.97323 34.6448 8.83086 34.2707 8.83086C33.8499 8.83086 33.4758 9.02066 33.1952 9.35283C32.9146 9.73243 32.7276 10.1595 32.7276 10.6814V16.0363H30.3428V6.59356H32.1197L32.2483 7.84863Z"
          fill="white"
        />
        <path
          d="M26.3531 20.9968C26.638 20.5793 27.0227 20.4189 27.4786 20.4189C27.8045 20.4189 28.0604 20.5954 28.2883 20.8042C28.4819 20.9968 28.5787 21.1895 28.6754 21.6146V24.2719H27.7066V22.054C27.7066 21.8642 27.6496 21.7124 27.5546 21.5796C27.4596 21.4467 27.3266 21.3898 27.1747 21.3898C27.0037 21.3898 26.8517 21.4656 26.7378 21.5985C26.6237 21.7503 26.5477 21.9211 26.5477 22.1299V24.2719H25.5789V20.4948H26.3008L26.3531 20.9968Z"
          fill="white"
        />
        <path
          d="M36.9526 23.6939C36.6676 24.1115 36.2829 24.2719 35.827 24.2719C35.5011 24.2719 35.2452 24.0954 35.0172 23.8866C34.8237 23.6939 34.727 23.5013 34.6302 23.0761V20.4189H35.5991V22.6367C35.5991 22.8266 35.6561 22.9784 35.751 23.1112C35.846 23.2441 35.979 23.301 36.131 23.301C36.3019 23.301 36.4539 23.2251 36.5679 23.0922C36.6818 22.9404 36.7578 22.7695 36.7578 22.5608V20.4189H37.7267V24.196H37.0048L36.9526 23.6939Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.1518 6.54815C10.1749 6.54815 9.34453 7.04213 8.70955 7.98067L8.46533 6.74573H7V20.0336H9.48495V15.4583C9.62011 15.6511 9.95358 15.8918 9.95358 15.8918C10.3942 16.0847 10.8587 16.2301 11.396 16.2301C12.031 16.2301 12.6171 16.0819 13.1055 15.6867C13.594 15.2915 13.9847 14.7481 14.2778 14.0072C14.5092 13.4221 14.5007 13.0219 14.4869 12.3686C14.4833 12.1945 14.4792 12.0026 14.4792 11.7843V11.5867C14.4792 11.4556 14.4798 11.3271 14.4803 11.2013C14.4846 10.2366 14.4882 9.42652 14.229 8.77104C13.9359 8.03007 13.4963 7.4867 12.959 7.09152C12.4217 6.74574 11.8356 6.54815 11.1518 6.54815ZM10.8111 8.81188C10.0218 8.81188 9.38192 9.4588 9.38192 10.2567V12.6649C9.38192 13.4629 10.0218 14.1098 10.8111 14.1098C11.6004 14.1098 12.2402 13.4629 12.2402 12.6649V10.2567C12.2402 9.4588 11.6004 8.81188 10.8111 8.81188Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.4399 24.2723C31.8319 24.2723 32.165 24.0712 32.4198 23.6891L32.5178 24.1919H33.1057V18.7818H32.1087V20.6446C32.015 20.5288 31.9446 20.4486 31.9446 20.4486C31.7878 20.348 31.5575 20.3304 31.3419 20.3304C31.0871 20.3304 30.852 20.3907 30.656 20.5517C30.46 20.7126 30.3032 20.9338 30.1856 21.2355C30.068 21.5371 30.0092 21.7182 30.0092 22.1405V22.2209C30.0092 22.6835 30.0876 23.0657 30.2052 23.3673C30.3228 23.669 30.4992 23.8902 30.7148 24.0511C30.9304 24.1919 31.1655 24.2723 31.4399 24.2723ZM31.5167 23.2435C31.8374 23.2435 32.0973 22.9807 32.0973 22.6565V21.6734C32.0973 21.3492 31.8374 21.0864 31.5167 21.0864C31.196 21.0864 30.9361 21.3492 30.9361 21.6734V22.6565C30.9361 22.9807 31.196 23.2435 31.5167 23.2435Z"
          fill="white"
        />
        <path
          d="M46.1587 6.5966C44.8432 6.5966 43.7768 7.67475 43.7768 9.0047V16.2291H46.1587V6.5966Z"
          fill="white"
        />
        <path
          d="M44.9678 4.67009C45.8886 4.67009 46.6351 3.91538 46.6351 2.9844C46.6351 2.05343 45.8886 1.29872 44.9678 1.29872C44.0469 1.29872 43.3004 2.05343 43.3004 2.9844C43.3004 3.91538 44.0469 4.67009 44.9678 4.67009Z"
          fill="white"
        />
        <path
          d="M48.9798 1.25027H51.3501V6.64054H53.352V8.73941H51.3036V12.9279C51.3036 13.4599 51.7302 13.8912 52.2564 13.8912H53.352V16.1807H51.8381C50.2595 16.1807 48.9798 14.8869 48.9798 13.2909V8.73941H47.4925V6.64054H48.9798V1.25027Z"
          fill="white"
        />
        <path
          d="M43.4909 19.4556H44.4437V20.4668H45.2536V21.1893H44.4437V22.827C44.4437 23.093 44.6569 23.3086 44.92 23.3086H45.3488V24.2719H44.4437C43.9175 24.2719 43.4909 23.8407 43.4909 23.3086V21.1893H42.8716V20.4668H43.4909V19.4556Z"
          fill="white"
        />
        <path
          d="M24.1498 20.4191H23.197V24.2721H24.1498V20.4191Z"
          fill="white"
        />
        <path
          d="M23.6734 19.6485C23.9365 19.6485 24.1498 19.4328 24.1498 19.1668C24.1498 18.9008 23.9365 18.6852 23.6734 18.6852C23.4103 18.6852 23.197 18.9008 23.197 19.1668C23.197 19.4328 23.4103 19.6485 23.6734 19.6485Z"
          fill="white"
        />
        <path
          d="M52.2791 24.0369C52.0885 24.5429 51.8541 24.9401 51.5759 25.2285C51.2977 25.522 50.9628 25.6688 50.5713 25.6688C50.2983 25.6688 50.0767 25.6308 49.9067 25.555V24.6961C50.1127 24.7056 50.0047 24.6961 50.1127 24.6961C50.2113 24.6961 50.2632 24.6961 50.3509 24.6961C50.8272 24.6961 50.9886 24.6441 51.1277 24.1735L49.6363 20.3709H50.8341L51.7073 22.8073H51.7537L52.5727 20.3709H53.6855L52.2791 24.0369Z"
          fill="white"
        />
        <path
          d="M39.8368 23.1667C39.8368 23.3424 39.899 23.4703 40.0234 23.5506C40.1477 23.6259 40.3022 23.6635 40.4867 23.6635C40.6471 23.6635 40.7895 23.6335 40.914 23.5732C41.0423 23.513 41.1065 23.4176 41.1065 23.2871C41.1065 23.1466 41.0483 23.0462 40.932 22.986C40.8156 22.9258 40.6352 22.8731 40.3904 22.8279C40.1337 22.7727 39.9231 22.7124 39.7586 22.6473C39.5941 22.582 39.4517 22.469 39.3313 22.3084C39.215 22.1479 39.1569 21.9245 39.1569 21.6385C39.1569 21.3775 39.217 21.1566 39.3373 20.976C39.4617 20.7902 39.6242 20.6522 39.8248 20.5619C40.0294 20.4666 40.2521 20.4189 40.4927 20.4189H40.6131C40.8257 20.4189 41.0262 20.4641 41.2148 20.5543C41.4074 20.6397 41.5619 20.7626 41.6782 20.9233C41.7945 21.0788 41.8526 21.257 41.8526 21.4578C41.8526 21.4979 41.8507 21.4653 41.8467 21.5005C41.8467 21.4955 41.8426 21.5306 41.8467 21.5005H41.0613C41.0613 21.1993 40.8858 21.1717 40.5168 21.1717C40.3483 21.1717 40.2119 21.2018 40.1076 21.262C40.0073 21.3222 39.9572 21.4051 39.9572 21.5104C39.9572 21.6359 40.0113 21.7238 40.1197 21.7739C40.2319 21.8241 40.4064 21.8718 40.6431 21.917C40.9119 21.9722 41.1286 22.0324 41.2931 22.0977C41.4616 22.1579 41.608 22.2708 41.7323 22.4365C41.8567 22.597 41.9189 22.8254 41.9189 23.1215C41.9189 23.5782 41.7905 23.9094 41.5337 24.1153C41.277 24.316 40.9501 24.4163 40.5529 24.4163H40.3543C40.1056 24.4163 39.883 24.3687 39.6864 24.2733C39.4898 24.1779 39.3354 24.0425 39.223 23.8668C39.1147 23.6911 39.0606 23.4904 39.0606 23.2645L39.0666 23.114H39.8368V23.1667Z"
          fill="white"
        />
        <path
          d="M47.4925 22.0082V24.2719H46.5398V20.4189H47.2543V20.9005C47.7307 20.0336 48.6835 20.1781 48.9217 20.4189V21.2376H48.2547C47.8338 21.2376 47.4925 21.5826 47.4925 22.0082Z"
          fill="white"
        />
        <path d="M7 21.3342H20.8151V22.0085H7V21.3342Z" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.5685 8.22681C25.0486 8.22681 25.2826 8.41244 25.5009 8.64617C25.7191 8.92663 25.85 9.25385 25.85 9.53429V10.142C25.6889 10.142 25.5318 10.1415 25.3789 10.1411C24.4139 10.1381 23.6134 10.1357 22.9729 10.2567C22.2309 10.4438 21.8189 10.7978 21.4262 11.2653C21.0333 11.7327 20.8151 12.3871 20.8151 13.2285C20.8151 14.3037 21.0769 15.0049 21.557 15.3788C22.0372 15.7528 22.6496 15.9865 23.4352 15.9865C24.8643 16.2261 25.8333 15.5775 26.5317 14.5491L26.7699 15.9865H28.1038V9.3019C28.1038 8.41379 27.8419 7.71261 27.2745 7.19842C26.7071 6.68422 25.8779 6.40376 24.7867 6.40376H24.4812C23.7829 6.40376 23.2155 6.49725 22.6918 6.73097C22.1681 6.96469 21.7753 7.29191 21.4698 7.71261C21.1643 8.13332 21.0333 8.60077 21.0333 9.11497C21.0333 9.15702 21.0422 9.19898 21.0518 9.24524C21.0581 9.27537 21.0649 9.25179 21.0698 9.23457C21.074 9.21933 21.0769 9.20902 21.0769 9.24524H23.2535C23.2535 9.16527 23.2819 9.1147 23.3225 9.04244C23.3382 9.01447 23.3557 8.98327 23.3742 8.94589C23.3916 8.91052 23.4098 8.87351 23.428 8.83449C23.4535 8.80718 23.4791 8.77808 23.5052 8.74826C23.6133 8.62498 23.7324 8.48905 23.9081 8.41379C24.1264 8.27356 24.3066 8.22681 24.5685 8.22681ZM23.7561 11.8638C23.2844 12.0958 23.0541 12.5425 23.0541 13.053C23.0541 13.4707 23.3063 13.8187 23.4778 13.958C23.6493 14.0972 23.9495 14.19 24.3354 14.19C24.5927 14.19 24.85 14.0972 25.1072 13.958C25.3645 13.7724 25.5789 13.5404 25.7504 13.2154C25.9219 12.8906 26.0077 12.5657 26.0077 12.1481V11.7169C25.8012 11.7169 25.5977 11.7128 25.4014 11.7089C24.7009 11.6948 24.0911 11.6826 23.7561 11.8638Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_22">
          <rect width="47" height="26" fill="white" transform="translate(7)" />
        </clipPath>
      </defs>
    </svg>
  );
}
